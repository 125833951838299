html,
body,
#__next {
  display: flex;
  flex-direction: column;
  height: 100%;
}

html {
  font-size: 14px;
}